import { useResponsiveProp, type ResponsivePropArgs } from '@telus-uds/components-web'
import styles from './BackgroundImage.module.css'
import React from 'react'
import { pickDataAttrs } from '@/siteBuilder/utils/dataAttributes'

export type BackgroundImageProps = {
  children: React.ReactElement[] | React.ReactElement
  image: ResponsivePropArgs<React.ReactElement>
  // src: ResponsivePropArgs<string>
  // alt: ResponsivePropArgs<string>
  width?: ResponsivePropArgs<number>
  height?: ResponsivePropArgs<number>
  resizeMode?: ResponsivePropArgs<'cover' | 'contain' | 'stretch' | 'center'>
  align?: ResponsivePropArgs<'start' | 'end' | 'center' | 'stretch'>
  position?: ResponsivePropArgs<'none' | 'bottom' | 'left' | 'right' | 'top'>
}

const BackgroundImage = ({
  children,
  // src, alt,
  width,
  height,
  image,
  resizeMode,
  align,
  position,
  ...rest
}: BackgroundImageProps) => {
  // const responsiveSrc = useResponsiveProp(src, '')
  // const responsiveAlt = useResponsiveProp(alt, '')
  const responsiveWidth = useResponsiveProp(width, 0)
  const responsiveHeight = useResponsiveProp(height, 0)
  const responsiveImage = useResponsiveProp(image, null)
  const responsiveResizeMode = useResponsiveProp(resizeMode, 'cover')
  const responsivePosition = useResponsiveProp(position, 'none')
  const responsiveAlign = useResponsiveProp(align, 'stretch')

  if (responsiveResizeMode === 'contain') {
    return (
      <div className={styles.container} {...pickDataAttrs(rest)}>
        <div
          className={`${styles.containedView} ${styles[`${responsivePosition}-${responsiveAlign}`]}`}
          style={{ width: responsiveWidth, height: responsiveHeight }}
        >
          {responsiveImage}
        </div>
        {children}
      </div>
    )
  }

  return (
    <div className={styles.container} {...pickDataAttrs(rest)}>
      {React.cloneElement(responsiveImage, {
        fill: true,
        className: `${styles.containedView} ${styles[responsiveResizeMode]}`,
      })}
      {children}
    </div>
  )
}

export default BackgroundImage

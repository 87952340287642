import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'
import { BlockRenderer } from '@/siteBuilder/renderer/contentful/BlockRenderer'

import BackgroundImage, { type BackgroundImageProps } from './BackgroundImage'
import type { IBackgroundImageFields } from 'contentful-types'

const ContentfulBox = withContentful<IBackgroundImageFields, BackgroundImageProps>(BackgroundImage, {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  children: (props) => <BlockRenderer block={props?.fields?.components} fieldPath={'.blocks'} />,
  image: (props) => ({
    xs: props?.fields?.xsImage && <BlockRenderer block={props?.fields?.xsImage} />,
    sm: props?.fields?.smImage && <BlockRenderer block={props?.fields?.smImage} />,
    md: props?.fields?.mdImage && <BlockRenderer block={props?.fields?.mdImage} />,
    lg: props?.fields?.lgImage && <BlockRenderer block={props?.fields?.lgImage} />,
    xl: props?.fields?.xlImage && <BlockRenderer block={props?.fields?.xlImage} />,
  }),
  // ? This is another approach. I'm not sure if it will needed.
  // src: (props) => ({
  //   xs: props?.fields?.xsImage?.fields?.imageAsset?.fields?.file?.url,
  //   sm: props?.fields?.smImage?.fields?.file?.url,
  //   md: props?.fields?.mdImage?.fields?.file?.url,
  //   lg: props?.fields?.lgImage?.fields?.file?.url,
  //   xl: props?.fields?.xlImage?.fields?.file?.url,
  // }),
  // alt: (props) => ({
  //   xs: props?.fields?.xsImage?.fields?.description,
  //   sm: props?.fields?.smImage?.fields?.description,
  //   md: props?.fields?.mdImage?.fields?.description,
  //   lg: props?.fields?.lgImage?.fields?.description,
  //   xl: props?.fields?.xlImage?.fields?.description,
  // }),
  width: (props) => ({
    xs: props?.fields?.xsImage?.fields?.imageAsset.fields.file.details.image.width,
    sm: props?.fields?.smImage?.fields?.imageAsset.fields.file.details.image.width,
    md: props?.fields?.mdImage?.fields?.imageAsset.fields.file.details.image.width,
    lg: props?.fields?.lgImage?.fields?.imageAsset.fields.file.details.image.width,
    xl: props?.fields?.xlImage?.fields?.imageAsset.fields.file.details.image.width,
  }),
  height: (props) => ({
    xs: props?.fields?.xsImage?.fields?.imageAsset.fields.file.details.image.height,
    sm: props?.fields?.smImage?.fields?.imageAsset.fields.file.details.image.height,
    md: props?.fields?.mdImage?.fields?.imageAsset.fields.file.details.image.height,
    lg: props?.fields?.lgImage?.fields?.imageAsset.fields.file.details.image.height,
    xl: props?.fields?.xlImage?.fields?.imageAsset.fields.file.details.image.height,
  }),
  resizeMode: (props) => ({
    xs: props?.fields?.xsResizeMode,
    sm: props?.fields?.smResizeMode,
    md: props?.fields?.mdResizeMode,
    lg: props?.fields?.lgResizeMode,
    xl: props?.fields?.xlResizeMode,
  }),
  align: (props) => ({
    xs: props?.fields?.xsAlign,
    sm: props?.fields?.smAlign,
    md: props?.fields?.mdAlign,
    lg: props?.fields?.lgAlign,
    xl: props?.fields?.xlAlign,
  }),
  position: (props) => ({
    xs: props?.fields?.xsPosition,
    sm: props?.fields?.smPosition,
    md: props?.fields?.mdPosition,
    lg: props?.fields?.lgPosition,
    xl: props?.fields?.xlPosition,
  }),
})

export default ContentfulBox
